import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
function PopoverC({placement,  title, textFull, text, children }) {
  return (
    <OverlayTrigger
      trigger="hover"
      placement={placement}
      delay={{ show: 250, hide: 400 }}
      overlay={
        <Popover id="popover-basic">
          <Popover.Title as="h3">{title}</Popover.Title>
          <Popover.Content>{text}</Popover.Content>
        </Popover>
      }
    >
      <div>{children}</div>
    </OverlayTrigger>
  );
}

export default PopoverC;
