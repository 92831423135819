export const navigationsDefault = [
  {
    name: "Painel",
    type: "extLink",
    description: "",
    tooltip: "Painel",
    icon: "i-Bar-Chart",
    path: "/painel",
  },
];

export const navigationsA = [
  {
    name: "Operações",
    description: "Lorem ipsum dolor sit.",
    type: "dropDown",
    icon: "i-Monitor-5",
    sub: [
      {
        icon: "i-Statistic",
        name: "Painel de Banhos",
        path: "/operacoes/painel-banhos",
        type: "link",
      },
      {
        icon: "i-Flag-2",
        name: "Eventos",
        path: "/operacoes/eventos",
        type: "link",
      },
      {
        icon: "i-Signal",
        name: "Monitoramento",
        path: "/operacoes/monitoramento",
        type: "link",
      },
      {
        icon: "i-Shutter",
        name: "Resumo",
        path: "/operacoes/resumo",
        type: "link",
      },
    ],
  },
  {
    name: "Estações",
    type: "extLink",
    description: "",
    tooltip: "Usuários",
    icon: "i-Cube-Molecule-2",
    path: "/estacao",
  },
  {
    name: "Usuários",
    type: "extLink",
    description: "",
    tooltip: "Usuários",
    icon: "nav-icon i-Boy",
    path: "/usuario",
  },
  {
    name: "Dispositivos",
    type: "extLink",
    description: "",
    tooltip: "Usuários",
    icon: "nav-icon i-Wifi",
    path: "/dispositivo",
  },
  {
    name: "Versões",
    type: "extLink",
    description: "",
    tooltip: "Usuários",
    icon: "nav-icon i-Code-Window",
    path: "/versao",
  },
  {
    name: "Geral",
    description: "Lorem ipsum dolor sit.",
    type: "dropDown",
    icon: "nav-icon i-Gears",
    sub: [
      {
        icon: "i-Gear-2",
        name: "Estados de dispositivo",
        path: "/geral/enum/estado-dispositivo",
        type: "link",
      },
      {
        icon: "i-Gear-2",
        name: "Situações de estação",
        path: "/geral/enum/situacao-estacao",
        type: "link",
      },
      {
        icon: "i-Gear-2",
        name: "Tipos de evento",
        path: "/geral/enum/tipo-evento",
        type: "link",
      },
      {
        icon: "i-Gear-2",
        name: "Tipos de sinal",
        path: "/geral/enum/sinal",
        type: "link",
      },
      {
        icon: "i-Gear-2",
        name: "Tipos de contrato",
        path: "/geral/enum/tipo-contrato",
        type: "link",
      },
      {
        icon: "i-Big-Data",
        name: "Tipo dispositivos",
        path: "/geral/tipo-dispositivo",
        type: "link",
      },
      {
        icon: "i-Geo21",
        name: "Municípios",
        path: "/geral/municipio",
        type: "link",
      },
    ],
  },
];

export const navigationsO = [
  {
    name: "Operações",
    description: "Lorem ipsum dolor sit.",
    type: "dropDown",
    icon: "i-Monitor-5",
    sub: [
      {
        icon: "i-Statistic",
        name: "Painel de Banhos",
        path: "/operacoes/painel-banhos",
        type: "link",
      },
      {
        icon: "i-Flag-2",
        name: "Eventos",
        path: "/operacoes/eventos",
        type: "link",
      },
      {
        icon: "i-Signal",
        name: "Monitoramento",
        path: "/operacoes/monitoramento",
        type: "link",
      },
    ],
  },
  {
    name: "Estações",
    type: "extLink",
    description: "",
    tooltip: "Usuários",
    icon: "i-Cube-Molecule-2",
    path: "/estacao",
  },
  {
    name: "Usuários",
    type: "extLink",
    description: "",
    tooltip: "Usuários",
    icon: "nav-icon i-Boy",
    path: "/usuario",
  },
  {
    name: "Dispositivos",
    type: "extLink",
    description: "",
    tooltip: "Usuários",
    icon: "nav-icon i-Wifi",
    path: "/dispositivo",
  },
];

export const navigationsG= [
 
  {
    name: "Operações",
    description: "Lorem ipsum dolor sit.",
    type: "dropDown",
    icon: "i-Monitor-5",
    sub: [
      {
        icon: "i-Statistic",
        name: "Painel de Banhos",
        path: "/operacoes/painel-banhos",
        type: "link",
      },
      {
        icon: "i-Flag-2",
        name: "Eventos",
        path: "/operacoes/eventos",
        type: "link",
      },
    ],
  },
  {
    name: "Estações",
    type: "extLink",
    description: "",
    tooltip: "Usuários",
    icon: "i-Cube-Molecule-2",
    path: "/estacao",
  },
  {
    name: "Sobre",
    type: "extLink",
    description: "",
    tooltip: "Usuários",
    icon: "i-Information",
    path: "/estacao",
  }
];


export const navigationsE= [
 
  {
    name: "Operações",
    description: "Lorem ipsum dolor sit.",
    type: "dropDown",
    icon: "i-Monitor-5",
    sub: [
      {
        icon: "i-Statistic",
        name: "Painel de Banhos",
        path: "/operacoes/painel-banhos",
        type: "link",
      },
      {
        icon: "i-Flag-2",
        name: "Eventos",
        path: "/operacoes/eventos",
        type: "link",
      },
      {
        icon: "i-Signal",
        name: "Monitoramento",
        path: "/operacoes/monitoramento",
        type: "link",
      },
    ],
  },
  {
    name: "Estações",
    type: "extLink",
    description: "",
    tooltip: "Usuários",
    icon: "i-Cube-Molecule-2",
    path: "/estacao",
  }
];
