import React, { useState, useEffect } from "react";
import { Col, ButtonGroup, ToggleButton } from "react-bootstrap";

function Select({
  name,
  placeholder,
  required,
  handleChange,
  options,
  value,
  label,
  multiple,
  cols,
  error,
  rest,
  valueString,
  showLabel,
}) {
  React.useEffect(() => {
    return () => {};
  }, []);
  const [radioValue, setRadioValue] = useState(value);
  const handleOnChange = (value) => {
    if (valueString) {
      handleChange(value);
    } else {
      handleChange(parseInt(value));
    }
    setRadioValue(value);
  };
  return (
    <Col
      col={cols.col}
      sm={cols.sm || cols.col}
      md={cols.md || cols.col}
      lg={cols.lg || cols.col}
      lx={cols.lx || cols.col}
      className={"form-group mb-3"}
      {...rest}
    >
      <div>{showLabel && <label htmlFor={name}>{label}</label>}</div>
      <ButtonGroup toggle>
        {options.map((radio, idx) => (
          <ToggleButton
            key={idx}
            type="radio"
            name="radio"
            value={radio.id}
            checked={value === radio.id}
            className={"text-white"}
            onChange={(event) => handleOnChange(event.target.value)}
          >
            {radio.value}
          </ToggleButton>
        ))}
      </ButtonGroup>
    </Col>
  );
}

export default Select;
