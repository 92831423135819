import { lazy } from "react";

const PainelBanhos = lazy(() => import("./painel-banho"));
const Eventos = lazy(() => import("./eventos/Eventos"));
const Resumo = lazy(() => import("./resumo"));
const Monitoramento = lazy(() => import("./monitoramento"));

const dispositivoRoutes = [
  {
    path: "/operacoes/painel-banhos",
    component: PainelBanhos,
  },
  {
    path: "/operacoes/eventos",
    component: Eventos,
  },
  {
    path: "/operacoes/resumo",
    component: Resumo,
  },
  {
    path: "/operacoes/monitoramento",
    component: Monitoramento,
  }
];

export default dispositivoRoutes;
