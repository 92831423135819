import React, { useState, useCallback, useRef, useEffect } from "react";
import styles from "./index.module.scss";
import classNames from "classnames";
import { Col } from "react-bootstrap";

 function Select({
  name,
  placeholder,
  required,
  handleChange,
  options,
  value,
  label,
  multiple,
  cols,
  error,
  rest,
  autoFocus,
}) {
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  useEffect(() => {
    if (autoFocus === true) {
      inputRef.current.focus();
    }
  }, [autoFocus]);
  useEffect(() => {
    return () => {};
  }, []);
  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);
  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);
  
  return (
    <Col
      col={cols.col}
      sm={cols.sm || cols.col}
      md={cols.md || cols.col}
      lg={cols.lg || cols.col}
      lx={cols.lx || cols.col}
      className={"form-group mb-3"}
    >
      <label htmlFor={name}>{label}</label>
      <select
        ref={inputRef}
        name={name}
        className="form-control"
        placeholder={placeholder}
        onChange={(event) => handleChange(parseInt(event.target.value))}
        multiple={multiple}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        {...rest}
        className={classNames(
          "form-control",
          styles.input,
          {
            [styles[`select--isFocused`]]: isFocused == true,
          },
          {
            [styles[`select--isFilled`]]: isFilled == true,
          },
          {
            [styles[`select--isError`]]: error == true,
          }
        )}
      >
        <option disabled selected key={0}>Selecione</option>
        {options.map((option) => (
          <option value={option.id} key={option.id} selected={value==option.id} >
            {option.value}
          </option>
        ))}
      </select>
    </Col>
  );
}

export default Select;
