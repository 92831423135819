import React from 'react';

// import { Container } from './styles';

function LoaderBubble() {
  return <div className="text-center">
  <div className="spinner spinner-bubble spinner-bubble-primary"></div>
</div>;
}

export default LoaderBubble;