import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const Listagem = lazy(() => import("./Listagem/Listagem"));

const dispositivoRoutes = [
  {
    path: "/versao",
    component: Listagem,
    auth: authRoles.admin
  },
];

export default dispositivoRoutes;
