import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import DropdownMenu from "react-bootstrap/DropdownMenu";
import { getTimeDifference } from "@utils";
import DropdownToggle from "react-bootstrap/DropdownToggle";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  setLayoutSettings,
  setDefaultSettings,
} from "app/redux/modules/layout/actions";
import { signOut as logoutUser } from "app/redux/modules/auth/actions";
import { setEstacaoClear } from "app/redux/modules/estacao/actions";

import { withRouter } from "react-router-dom";

import { merge } from "lodash";
import { ImWhatsapp } from "react-icons/im";

class Layout1Header extends Component {
  state = {
    showSearchBox: false,
  };

  handleMenuClick = () => {
    let { setLayoutSettings, settings } = this.props;
    setLayoutSettings(
      merge({}, settings, {
        layoutSettings: {
          leftSidebar: {
            open: settings.layoutSettings.leftSidebar.secondaryNavOpen
              ? true
              : !settings.layoutSettings.leftSidebar.open,
            secondaryNavOpen: false,
          },
        },
      })
    );
  };

  toggleFullScreen = () => {
    if (document.fullscreenEnabled) {
      if (!document.fullscreen) document.documentElement.requestFullscreen();
      else document.exitFullscreen();
    }
  };

  handleSearchBoxOpen = () => {
    let { setLayoutSettings, settings } = this.props;
    setLayoutSettings(
      merge({}, settings, {
        layoutSettings: {
          searchBox: {
            open: true,
          },
        },
      })
    );
  };

  render() {
    let { estacao } = this.props;
    return (
      <div className="main-header">

        <a class=" d-none d-sm-inline-block"
          href={`${window.location.href}`} >
          <div className="logo">
            <img src="/assets/images/logo.png" alt="" />
          </div>
        </a>

        <div className="menu-toggle" onClick={this.handleMenuClick}>
          <div></div>
          <div></div>
          <div></div>
        </div>

        <div className="d-none d-lg-flex align-items-center">
          {estacao?.estacao.length > 0 ? (
            <div
              onClick={this.handleSearchBoxOpen}
              className={"ml-4 badge badge-primary p-2 text-white"}
            >
              {estacao?.estacao.length == 1 ? (
                <span>{estacao?.estacao[0].nome}</span>
              ) : (
                <span>Estações selecionadas</span>
              )}
            </div>
          ) : (
            <div className="search-bar">
              <input
                type="text"
                placeholder={`Definir estação`}
                onFocus={this.handleSearchBoxOpen}
                id="pesquisa-header"
                name="pesquisa"
                autocomplete="off"
              />
              <i className="search-icon text-muted i-Magnifi-Glass1"></i>
            </div>
          )}
        </div>

        <div style={{ margin: "auto" }}></div>

        <div className="header-part-right">
          <i
            className="i-Full-Screen header-icon d-none d-sm-inline-block"
            data-fullscreen
            onClick={this.toggleFullScreen}
          ></i>

          <a class=" d-none d-sm-inline-block" target="_blank"
            href="https://api.whatsapp.com/send?phone=5547991178879&text=Ol%C3%A1,%20Estou%20te%20contatando%20pelo%20sistema%20web%20Flow%20Banhos.%20Pode%20me%20ajudar?">
            <span  aria-haspopup="true" aria-expanded="false">
                <ImWhatsapp size="20" color="#4b76ab" className="mr-2 " />
                Atendimento
            </span>
          </a>

          <div className="user col align-self-end">
            <Dropdown>
              <DropdownToggle
                as="span"
                className="toggle-hidden cursor-pointer"
              >
                <img
                  src="/assets/images/user.png"
                  id="userDropdown"
                  alt=""
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                />
              </DropdownToggle>
              <DropdownMenu>
                <Link
                  to="/minha-conta"
                  className="dropdown-item cursor-pointer"
                >
                  Minha Conta
                </Link>
                <Link
                  to="/session/login"
                  className="dropdown-item cursor-pointer"
                  onClick={this.props.logoutUser}
                >
                  Sair
                </Link>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      </div>
    );
  }
}

Layout1Header.propTypes = {
  setLayoutSettings: PropTypes.func.isRequired,
  setDefaultSettings: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  setDefaultSettings: PropTypes.func.isRequired,
  setLayoutSettings: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  user: state.user,
  settings: state.layout.settings,
  estacao: state.estacao,
});

export default withRouter(
  connect(mapStateToProps, {
    setLayoutSettings,
    setDefaultSettings,
    logoutUser,
    setEstacaoClear,
  })(Layout1Header)
);
