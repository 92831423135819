import React, { useState, useCallback, useRef } from "react";
import styles from "./index.module.scss";
import classNames from "classnames";
import { Col } from "react-bootstrap";
import NumberFormat from "react-number-format";
function Input({
  name,
  classname,
  label,
  error,
  cols,
  handleChange,
  mask,
  number,
  maximum,
  ...rest
}) {
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);
  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    setIsFilled(!!inputRef.current?.value);
  }, []);
 
  const handleOnChange = (value) => {
    if(number){
      handleChange(parseInt(value))
    }else{
      handleChange(value)
    }
    }
  const handleChangeInput = useCallback((event) => {
    if(Number.isInteger(event.target.value)){
      if(maximum){
        event.target.value.length<maximum &&handleOnChange(event.target.value)
      }else{
        handleOnChange(event.target.value)
      }
    }
  }, []);
  return ( 
    <Col
      col={cols.col}
      sm={cols.sm || cols.col}
      md={cols.md || cols.col}
      lg={cols.lg || cols.col}
      lx={cols.lx || cols.col}
      className={"form-group mb-3"}
    >
      <label htmlFor={name}>{label}</label>
      <NumberFormat
        thousandSeparator={true}
        ref={inputRef}
        id={name}
        onChange={(event) =>handleChangeInput(event)}
        className={classNames(
          "form-control",
          styles.input,
          {
            [styles[`input--isFocused`]]: isFocused == true,
          },
          {
            [styles[`input--isFilled`]]: isFilled == true,
          },
          {
            [styles[`input--isError`]]: !!error == true,
          }
        )}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        {...rest}
      />
           {error&&<span>{error[0]}</span>}
    </Col>
  );
}

export default Input;
