export const SET_LAYOUT_SETTINGS = "LAYOUT_SET_SETTINGS";
export const SET_DEFAULT_LAYOUT_SETTINGS = "LAYOUT_SET_DEFAULT_SETTINGS";

export function setLayoutSettings(data) {
  return {
    type: SET_LAYOUT_SETTINGS,
    data: data,
  };
}
export function setDefaultSettings(data) {
  return {
    type: SET_DEFAULT_LAYOUT_SETTINGS,
    data: data,
  };
}