import React, { useState, useCallback, useRef, useEffect } from "react";
import validate from "validate.js";
import { Col } from "react-bootstrap";
import styles from "./index.module.scss";
import classNames from "classnames";


function Input(
  {
    name,
    classname,
    label,
    error,
    cols,
    handleChange,
    handleBlur,
    value,
    maximum,
    type,
    constraint, 
    ...rest
  },
  props
) {
  useEffect(() => {
    if (props.autoFocus == true) {
      inputRef.current.focus();
    }
  }, []);
  useEffect(() => {
    return () => {};
  }, []);
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [errors, setErrors] = useState(false);
  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);
  const handleInputBlur = useCallback( async() => {
    setIsFocused(false);
    setIsFilled(!!inputRef.current?.value);
    if(constraint){
      await validate.async({[name]:inputRef.current.value}, {...constraint}).then(null, (err) => {
        setErrors(err);
      });
    }
   
  }, []);
  const handleChangeString = useCallback((event) => {
    if (maximum) {
      event.target.value.length - 1 < maximum &&
        handleChange(event.target.value);
    } else {
      handleChange(event.target.value);
    }
  }, []);
  const handleChangeNumber = useCallback((event) => {
    if (Number.isInteger(parseInt(event.target.value))) {
      maximum
        ? event.target.value.length - 1 < maximum &&
          handleChange(parseInt(event.target.value))
        : handleChange(parseInt(event.target.value));
    }
  }, []);
  const handleChangePassword = useCallback((event) => {
    handleChange(event.target.value);
  }, []);
  return (
    <Col
      col={cols.col}
      sm={cols.sm || cols.col}
      md={cols.md || cols.col}
      lg={cols.lg || cols.col}
      lx={cols.lx || cols.col}
      className={"form-group mb-3"}
    >
      <label htmlFor={name}>{label}</label>
      <input
        ref={inputRef}
        id={name}
        onChange={(event) => {
          switch (type) {
            case "number":
              return handleChangeNumber(event);
            case "password":
              return handleChangePassword(event);
            default:
              return handleChangeString(event);
          }
        }}
        className={classNames(
          "form-control",
          styles.input,
          {
            [styles[`input--isFocused`]]: isFocused == true,
          },
          {
            [styles[`input--isFilled`]]: isFilled == true,
          },
          {
            [styles[`input--isError`]]: !!error == true,
          }
        )}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        value={value}
      
        {...rest}
        autocomplete="off"
      ></input>
      {errors && <span>{errors[0]}</span>}
    </Col>
  );
}

export default Input;
