import React, { useState, useEffect } from "react";
import { classList } from "@utils";
import { buscarEstacao } from "../../app/services/graphql/Estacao";
import { useSubscription } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Button } from "react-bootstrap";
import {
  setEstacaoPadrao,
  setEstacaoClear,
  setEstacaoAdd,
  setEstacaoRemove,
  setEstacaoArray,
} from "app/redux/modules/estacao/actions";
import { connect } from "react-redux";
import _ from "lodash";

function GullSearch({
  open,
  handleClose,
  setEstacaoClear,
  estacaoState,
  setEstacaoArray,
  setEstacaoRemove,
  setEstacaoAdd,
}) {
  const [estacaoCurrent, setEstacaoCurrent] = useState("");
  const { data } = useSubscription(
    gql`
      ${buscarEstacao(estacaoCurrent ? estacaoCurrent : "")}
    `
  );
  useEffect(() => {
    return () => {};
  }, []);

  function definirRede(redeNome) {
    let redesExist= estacaoState.estacao.filter((rede) => {
      return rede.nome_rede == redeNome;
    });
    if(redesExist[0]) return;

    let redes = data.estacao.filter((rede) => {
      return rede.nome_rede == redeNome;
    });
   
    setEstacaoArray(redes);
  }
  return (
    <div
      className={classList({
        "search-ui": true,
        open: open,
      })}
    >
      <div className="search-header o-hidden">
        <img src="/assets/images/logo.png" alt="" className="logo float-left" />
        <button
          className="search-close btn btn-icon bg-transparent float-right mt-2"
          onClick={handleClose}
        >
          <i className="i-Close-Window text-22 text-muted"></i>
        </button>
        <button
          className="search-close btn btn-icon bg-transparent float-right mt-2"
          onClick={() => setEstacaoClear()}
        >
          Limpar
        </button>
      </div>

      <input
        type="text"
        placeholder="Estação"
        className="search-input mb-0"
        
        value={estacaoCurrent}
        onChange={(event) => setEstacaoCurrent(event.target.value)}
        id='pesquisa'
        name='pesquisa'
        autocomplete="off"
      />
      <div className="search-title">
        <div>
          {estacaoState && estacaoState.estacao ? estacaoState.estacao.map((item) => {
            return (
              <div className={"badge badge-primary p-2 text-white mr-2"}>
                <div className=" d-flex">
                  <div> {item.nome}</div>
                  <div
                    className="pl-3"
                    onClick={() => setEstacaoRemove(item.id_estacao)}
                  >
                    <i className="nav-icon i-Close-Window font-weight-bold  text-white" />
                  </div>
                </div>
              </div>
            );
          }) : ""}
        </div>
      </div>
      {data?.estacao[0]&& <div className="search-title">
        <span className="text-muted">Estações encontradas</span>
      </div>}
     

      <div className="search-results list-horizontal">
        {data?.estacao.map((item, index) => {
          let estacaoAdicionada = _.filter(estacaoState.estacao, {
            id_estacao: item.id_estacao,
          });

          return (
            <div key={index} className="list-item col-md-12 p-0">
              <div className="card o-hidden flex-row mb-4 d-flex">
                <div className="flex-grow-1 pl-2 d-flex">
                  <div className="card-body align-self-center d-flex flex-column justify-content-between align-items-lg-center flex-lg-row p-3">
                    <a className="w-40 w-sm-100">
                      <div className="item-title">
                        {item.nome}{" "}
                        <span
                          className={"ml-4 badge badge-primary p-2 text-white"}
                        >
                          {item.nome_rede}
                        </span>
                      </div>
                    </a>
                    {!estacaoAdicionada[0] ? (
                      <p className="m-0 text-muted text-small w-15 w-sm-100">
                        {item.nome_rede && (
                          <Button
                            variant={"primary"}
                            onClick={() => definirRede(item.nome_rede)}
                            className="btn-sm text-white mr-3"
                          >
                            Definir rede
                          </Button>
                        )}
                        <Button
                          variant={"primary"}
                          onClick={() => setEstacaoAdd(item)}
                          className="btn-sm text-white"
                        >
                          <i className="nav-icon i-Add font-weight-bold  text-white" />
                        </Button>
                      </p>
                    ) : (
                      <p className="m-0 text-muted text-small w-15 w-sm-100">
                        <Button
                          variant={"danger"}
                          onClick={() => setEstacaoRemove(item.id_estacao)}
                          className="btn-sm text-white"
                        >
                          <i className="nav-icon i-Close-Window font-weight-bold  text-white" />
                        </Button>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  estacaoState: state.estacao || [],
});

export default connect(mapStateToProps, {
  setEstacaoPadrao,
  setEstacaoClear,
  setEstacaoAdd,
  setEstacaoRemove,
  setEstacaoArray,
})(GullSearch);
