import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  navigationsA,
  navigationsG,
  navigationsO,
  navigationsE,
  navigationsDefault,
} from "../../navigations";
import { merge } from "lodash";
import { classList, isMobile } from "@utils";
import Srcollbar from "react-perfect-scrollbar";
import { DropDownMenu } from "@gull";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  setLayoutSettings,
  setDefaultSettings,
} from "app/redux/modules/layout/actions";
import { signOut as logoutUser } from "app/redux/modules/auth/actions";
import { withRouter } from "react-router-dom";
import ScrollBar from "react-perfect-scrollbar";

class LayoutSidenav extends Component {
  windowListener = null;

  state = {
    selectedItem: null,
    navOpen: true,
    secondaryNavOpen: false,
  };

  onMainItemMouseEnter = (item) => {
    if (item.type === "dropDown") {
      this.setSelected(item);
      this.openSecSidenav();
    } else {
      this.setSelected(item);
      this.closeSecSidenav();
    }
  };

  onMainItemMouseLeave = () => {
    // this.closeSecSidenav();
  };

  setSelected = (selectedItem) => {
    this.setState({ selectedItem });
  };

  removeSelected = () => {
    this.setState({ selectedItem: null });
  };

  openSecSidenav = () => {
    let { setLayoutSettings, settings } = this.props;
    setLayoutSettings(
      merge({}, settings, {
        layoutSettings: {
          leftSidebar: {
            secondaryNavOpen: true,
          },
        },
      })
    );
  };

  closeSecSidenav = () => {
    let { setLayoutSettings, settings } = this.props;
    let other = {};

    if (isMobile()) {
      other.open = false;
    }

    setLayoutSettings(
      merge({}, settings, {
        layoutSettings: {
          leftSidebar: {
            ...other,
            secondaryNavOpen: false,
          },
        },
      })
    );
  };

  closeSidenav = () => {
    let { setLayoutSettings, settings } = this.props;
    setLayoutSettings(
      merge({}, settings, {
        layoutSettings: {
          leftSidebar: {
            open: false,
            secondaryNavOpen: false,
          },
        },
      })
    );
  };

  openSidenav = async () => {
    let { setLayoutSettings, settings } = this.props;
    await setLayoutSettings(
      merge({}, settings, {
        layoutSettings: {
          leftSidebar: {
            open: true,
          },
        },
      })
    );
  };

  componentDidMount() {
    if (this.state.selectedItem === null) this.closeSecSidenav();

    if (window)
      if (window.innerWidth < 1200) {
        this.closeSidenav();
      } else {
        this.openSidenav();
      }

    this.windowListener = window.addEventListener("resize", ({ target }) => {
      if (window.innerWidth < 1200) {
        this.closeSidenav();
      } else {
        this.openSidenav();
      }
    });
  }

  componentWillUnmount() {
    if (this.windowListener) {
      window.removeEventListener("resize", this.windowListener);
    }
  }

  render() {
    let { settings, user } = this.props;
    let navigations = navigationsDefault;
    if (user.profile) {
      switch (user.profile.tipo_perfil) {
        case "A":
          navigations = navigationsA;
          break;
        case "G":
          navigations = navigationsG;
          break;
        case "O":
          navigations = navigationsO;
          break;
        case "E":
          navigations = navigationsE;
          break;
      }
    }

    return (
      <div className="side-content-wrap">
        <Srcollbar
          className={classList({
            "sidebar-left o-hidden rtl-ps-none": true,
            open: settings.layoutSettings.leftSidebar.open,
          })}
          // id="mainsidenav"
        >
          <ul className="navigation-left">
            {navigations.map((item, i) => (
              <li
                className={classList({
                  "nav-item": true,
                  active: this.state.selectedItem === item,
                })}
                onMouseEnter={() => {
                  this.onMainItemMouseEnter(item);
                }}
                onMouseLeave={this.onMainItemMouseLeave}
                key={i}
              >
                {item.path && item.type !== "extLink" && (
                  <Link className="nav-item-hold" to={item.path}>
                    <i className={`nav-icon ${item.icon}`}></i>
                    <span className="nav-text">{item.name}</span>
                  </Link>
                )}
                {item.path && item.type === "extLink" && (
                  <Link className="nav-item-hold" to={item.path}>
                    <i className={`nav-icon ${item.icon}`}></i>
                    <span className="nav-text text-initial ">{item.name}</span>
                  </Link>
                )}
                {!item.path && (
                  <div className="nav-item-hold">
                    <i className={`nav-icon ${item.icon}`}></i>
                    <span className="nav-text text-initial ">{item.name}</span>
                  </div>
                )}
                <div className="triangle"></div>
              </li>
            ))}
          </ul>
        </Srcollbar>

        <ScrollBar
          className={classList({
            "sidebar-left-secondary o-hidden rtl-ps-none": true,
            open: settings.layoutSettings.leftSidebar.secondaryNavOpen,
          })}
        >
          {this.state.selectedItem && this.state.selectedItem.sub && (
            <DropDownMenu
              menu={this.state.selectedItem.sub}
              closeSecSidenav={this.closeSecSidenav}
            ></DropDownMenu>
          )}
          <span></span>
        </ScrollBar>
        <div
          onMouseEnter={this.closeSecSidenav}
          className={classList({
            "sidebar-overlay": true,
            open: settings.layoutSettings.leftSidebar.secondaryNavOpen,
          })}
        ></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  setDefaultSettings: PropTypes.func.isRequired,
  setLayoutSettings: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  user: state.user,
  settings: state.layout.settings,
});

export default withRouter(
  connect(mapStateToProps, {
    setLayoutSettings,
    setDefaultSettings,
    logoutUser,
  })(LayoutSidenav)
);
