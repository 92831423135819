import { combineReducers } from 'redux';
import auth from './auth/reducer';
import user from './user/reducer';
import layout from './layout/reducer';
import enumStyle from './enumStyle/reducer';
import estacao from './estacao/reducer';
import permissoes from './permissoes/reducer';

export default combineReducers({
  auth,
  user,
  layout, 
  enumStyle,
  estacao,
  permissoes
});