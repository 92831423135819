import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

const GenericSearchTable = ({
  columns,
  data = [],
  customOptions,
  rowStyles,
  defaultSorted,
}) => {
  const { SearchBar } = Search;
 
  const paginationOptions = {
    // custom: true,
    paginationSize: 5,
    pageStartIndex: 1,
    firstPageText: 'Inicio',
    prePageText: 'Voltar',
    nextPageText: 'Avançar',
    lastPageText: 'Fim',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    hideSizePerPage: true,
    totalSize: data?.length,
    ...customOptions,
  };

  return (
    <ToolkitProvider striped keyField="id" data={data} columns={columns} search>
      {props => (
        <>
          <BootstrapTable
            defaultSorted={defaultSorted}
            rowStyle={{ ...rowStyles }}
            {...props.baseProps}
            bootstrap4
            pagination={paginationFactory(paginationOptions)}
            noDataIndication="Table is empty"
          />
        </>
      )}
    </ToolkitProvider>
  );
};

export default GenericSearchTable;
