import React, { useState } from "react";
import { Col, Form } from "react-bootstrap";

function Switch({
  name,
  handleChange,
  value,
  label,
  cols,
  labelAbove,
  labelAboveOff,
}) {
  const [isSwitchOn, setIsSwitchOn] = useState(value);
  const onSwitchAction = () => {
    setIsSwitchOn(!isSwitchOn);
    handleChange(!isSwitchOn)
  };

  labelAbove = label;
  if (labelAboveOff) {
    labelAbove = " ";
  }
 
  return (
    <Col
      col={cols.col}
      sm={cols.sm || cols.col}
      md={cols.md || cols.col}
      lg={cols.lg || cols.col}
      lx={cols.lx || cols.col}
      className={"form-group mb-3"}
    >

      {labelAbove||<label htmlFor={name}>{labelAbove}</label>}
        
      <Form.Check
        onChange={onSwitchAction}
        checked={value}
        name={name}
        type="switch"
        id={name}
        label={label}
      />
    </Col>
  );
}

export default Switch;
