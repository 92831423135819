export const subscriptionList = (entidade) =>  `subscription getEnum {
  enum(where: {entidade: {_eq: "${entidade}"}}) {
    created_at
    deleted_at
    observacao
    updated_at
    descricao
    entidade
    id_enum
    codigo
  }
}

`;
export const queryList = (entidade) =>  `query getEnum {
  enum(where: {entidade: {_eq: "${entidade}"}}) {
    created_at
    deleted_at
    observacao
    updated_at
    descricao
    entidade
    id_enum
    codigo
  }
}

`;