import React, { Suspense } from "react";
import "../styles/app/app.scss";
import "./config/ReactotronConfig";
import { Router } from "react-router-dom";
import AppContext from "./appContext";
import history from "@history";

import routes from "./RootRoutes";
import { renderRoutes } from "react-router-config";
import Auth from "./auth/Auth";
import RootRoutes from "./RootRoutes";
import { Loading } from "@gull";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import "react-toastify/dist/ReactToastify.css";
import { ApolloProvider } from "@apollo/react-hooks";
import { useSelector } from "react-redux";
import createApolloClient from "./services/connection-broker-graphql/createApolloClient"
import storage from "./services/storage";

function App() {
  const access = storage.getString("access");
  const {permissoes} = useSelector((state) => state.permissoes);
  const handleCheckPermission = (permission, redirect = false) => {
    const hasPermission = permissoes.includes(permission);
    if (!hasPermission && redirect) {
      history.push("/");
      return NotificationManager.warning(
        "Sua função não tem privilégios para acessar este recurso"
      );
    } else {
      return hasPermission;
    }
  }; 
  return (
    <ApolloProvider client={createApolloClient(access)}>
      <AppContext.Provider value={{ routes, handleCheckPermission }}>
        <Auth>
          <Suspense fallback={<Loading></Loading>}>
            <Router history={history}>{renderRoutes(RootRoutes)}</Router>
          </Suspense>
        </Auth>

        <NotificationContainer leaveTimeout={10000} />
      </AppContext.Provider>
    </ApolloProvider>
  );
}

export default App;
