import axios from 'axios';
import config from '../../config/config'
const api = axios.create({
  baseURL:`${config.api.baseUrl}`,
});
axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
 return Promise.reject(error);
});
export default api;
