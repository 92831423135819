import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { WebSocketLink } from "apollo-link-ws";
import { onError } from "apollo-link-error";
import { ApolloLink } from "apollo-link";
import { store } from "../../redux";
import config from "../../config/config";
import history from "@history";
import storage from "../storage";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) graphQLErrors.map(({ message, extensions }) => {
  });
  if (networkError) {
   history.push("/session/login"); // redirect to network-error route
  }
});
const httpLink = (access) => {
 
  return new WebSocketLink({
    uri: config.graphql.uriWss,
    options: {
      reconnect: true,
      lazy: true,
      connectionParams: {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      },
    },
  });
};
const createApolloClient = (access) => {
  
 


  return new ApolloClient({
    link: ApolloLink.from([errorLink, httpLink(access)]),
    cache: new InMemoryCache(),
  });
};

export default createApolloClient;
