import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const Listagem = lazy(() => import("./Listagem/Listagem"));
const Novo = lazy(() => import("./Novo/Novo"));
const User = lazy(() => import("./User"));
const usuarioRoutes = [
  {
    path: "/usuario/cadastro",
    component: Novo,
  },
  {
    path: "/usuario",
    component: Listagem,
    exact:true,
  },
  {
    path: "/usuario/:usuario_id",
    component: User,
    exact:true,
  },
];

export default usuarioRoutes;
