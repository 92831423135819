export function updatePermissoes(data) {
  return {
    type: "@permissoes/ATULIZAR",
    payload: data,
  };
}
export function clearPermissoes() {
  return {
    type: "@permissoes/LIMPAR",
    payload: {},
  };
}
