const config = {
  api: {
    baseUrl: "https://api.vp.eletromais.tec.br/api/",
  },
  graphql: {
    uri: "wss://hasura.vp.eletromais.tec.br/v1/graphql",
    uriWss: "wss://hasura.vp.eletromais.tec.br/v1/graphql",
  },
};

export default config;
