import React, { useEffect,  Fragment } from "react";
import { connect } from "react-redux";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { enumSet } from "../../redux/modules/enumStyle/actions";
import { queryList } from "../../services/graphql/Enum";

const Footer = ({ enumSet }) => {
  const { loading, error, data } = useQuery(
    gql`${queryList("ESTADO_DISPOSITIVO")}`
  );
  useEffect(() => {
    enumSet(data);
  }, [data]);
  return (
    <Fragment>
      <div className="flex-grow-1"></div>
      <div className="app-footer">
        <div className="footer-bottom  pt-2 d-flex justify-content-between align-items-center">
          <div className="m-0">
            Está com dúvidas? <a href="#">Clique aqui</a> ou ligue para nossa
            central <a href="tel:4733630053">47 3363 0053</a>
          </div>
          <div className="m-0">
            © 2020 Eletro+ Tecnologia – Todos os direitos reservados
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  enumStyle: state.enumStyle,
});

export default connect(mapStateToProps, { enumSet })(Footer);
