import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const Lista = lazy(() => import("./Listagem/Listagem"));
const dispositivoRoutes = [
  {
    path: "/dispositivo",
    component: Lista,
  },

];

export default dispositivoRoutes;
