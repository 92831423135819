const keyStore = 'eletro-mais-nuvem';
export default (function () {
  return {
    get: (name) => {
      try {
        return JSON.parse(localStorage.getItem(`${keyStore}:${name}`));
      } catch (error) {}
    },
    getString: (name) => {
      try {
        return localStorage.getItem(`${keyStore}:${name}`);
      } catch (error) {}
    },
    set: (name, data) => {
      try {
        localStorage.setItem(`${keyStore}:${name}`, JSON.stringify(data));
      } catch (error) {}
    },
    setString: (name, data) => {
      try {
        localStorage.setItem(`${keyStore}:${name}`, data);
      } catch (error) {}
    },
    del: (name) => {
      try {
        localStorage.removeItem(`${keyStore}:${name}`);
      } catch (error) {}
    },
    clear: (name) => {
        try {
            localStorage.clear();
        } catch (error) {}
      },
  };
})();
