import React from "react";
import { Redirect } from "react-router-dom";
import dashboardRoutes from "./pages/dashboard/dashboardRoutes";
import userRoutes from "./pages/user/userRoutes";
import sessionsRoutes from "./pages/sessions/sessionsRoutes";
import estacaoRoutes from "./pages/estacao/estacaoRoutes";
import dispositivoRoutes from "./pages/dispositivo/dispositivoRoutes";
import geralRoutes from "./pages/geral/geralRoutes";
import versaoRoutes from "./pages/versao/versoesRoutes";
import usuarioRoutes from "./pages/usuario/usuarioRoutes";
import operacoesRoutes from "./pages/operacoes/operacoesRoutes";
import AuthGuard from "./auth/AuthGuard";



const routes = [
  ...sessionsRoutes,
  {
    path: "/",
    component: AuthGuard,
    routes: [
      ...dashboardRoutes,
      ...userRoutes,
      ...estacaoRoutes,
      ...dispositivoRoutes,
      ...geralRoutes,
      ...versaoRoutes,
      ...usuarioRoutes,
      ...operacoesRoutes,
    ]
  }
];

export default routes;
