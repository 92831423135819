import React, { useState, useCallback, useRef, useEffect } from "react";
import styles from "./index.module.scss";
import validate from "validate.js";
import classNames from "classnames";
import { Col } from "react-bootstrap";
function Textarea({
  name,
  classname,
  label,
  error,
  cols,
  value,
  handleChange,
  maximum,
  constraint,
  type,
  handleChangeError,
  autoFocus,
  ...rest
}) {
  useEffect(() => {
    if (autoFocus === true) {
      inputRef.current.focus();
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    return () => {};
  }, []);
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [errors, setErrors] = useState(error);
  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);
  const handleInputBlur = useCallback(async () => {
    setIsFocused(false);
    setIsFilled(!!inputRef.current?.value);
    if (constraint) {
      const inputValue = inputRef.current.value;
      await validate
        .async({ [name]: inputValue }, { [name]: constraint })
        .then(null, (err) => {
          setErrors(err[name][0]);
          handleChangeError(true);
        });
    }
    // eslint-disable-next-line
  }, []);
  const handleChangeInput = useCallback((event) => {
    if (maximum) {
      if (event.target.value.length < maximum) {
        handleChange(event.target.value);
      }
    } else {
      handleChange(event.target.value);
    }
    setErrors(false);
  }, []);
  return (
    <Col
      col={cols.col}
      sm={cols.sm || cols.col}
      md={cols.md || cols.col}
      lg={cols.lg || cols.col}
      lx={cols.lx || cols.col}
      className={"form-group mb-3"}
    >
      <label htmlFor={name}>{label}</label>
      <textarea
        ref={inputRef}
        id={name}
        onChange={(event) => handleChangeInput(event)}
        className={classNames(
          "form-control",
          styles.input,
          {
            [styles[`input--isFocused`]]: isFocused == true,
          },
          {
            [styles[`input--isFilled`]]: isFilled == true,
          },
          {
            [styles[`input--isError`]]: !!errors == true,
          },
          {
            [styles[`input--isError`]]: !!error == true,
          }
        )}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        {...rest}
        rows="3"
        value={value}
      ></textarea>
      {errors && <span>{errors}</span>}
    </Col>
  );
}

export default Textarea;
