import React, { useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import { GrDocumentCsv } from "react-icons/gr";

const { ExportCSVButton } = CSVExport;

const GenericSearchTable = ({
  columns,
  data = [],
  customOptions,
  prependDefaultSizePerPageList,
  rowStyles,
  defaultSorted,
  keyField = "id",
  exportCSV,
  expandRow,
  afterSearch = () => {},
  buttonHeader = <span></span>,
}) => {
  const { SearchBar } = Search;

  const paginationOptions = {
    // custom: true,
    paginationSize: 5,
    pageStartIndex: 1,
    firstPageText: "Inicio",
    prePageText: "Voltar",
    nextPageText: "Avançar",
    lastPageText: "Fim",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    hideSizePerPage: false,
    showTotal: true,
    hidePageListOnlyOnePage: false,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "25",
        value: 25,
      },
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
      {
        text: "Todos",
        value: data.length,
      },
    ],
    paginationTotalRenderer: (from, to, size) => {
      return (
        <span className="react-bootstrap-table-pagination-total ml-3">
          Mostrando de {from} a {to} de {size}
        </span>
      );
    }, // custom the pagination total
    ...customOptions,
  };

  if (prependDefaultSizePerPageList) {
    paginationOptions.sizePerPageList = prependDefaultSizePerPageList.concat(
      paginationOptions.sizePerPageList
    );
  }

  return (
    <ToolkitProvider
      striped
      keyField={keyField}
      data={data}
      columns={columns}
      expandRow={expandRow}
      exportCSV={exportCSV}
      search={{
        afterSearch: (newResult) => afterSearch(newResult),
      }}
    >
      {(props) => {
        const pagination = paginationFactory(paginationOptions);
        return (
          <>
            <div className="d-flex justify-content-end align-items-center">
              <span className="mb-2 mr-1">Buscar:</span>
              <SearchBar
                {...props.searchProps}
                placeholder=" "
                className="mb-0"
              />
  <div className='ml-3' style={{marginTop:'-7px'}}>
  {buttonHeader}
  </div>
              
            </div>
            <BootstrapTable
              defaultSorted={defaultSorted}
              rowStyle={{ ...rowStyles }}
              striped
              {...props.baseProps}
              bootstrap4
              pagination={pagination}
              bordered={false}
              noDataIndication="A tabela está vazia"
            />
          </>
        );
      }}
    </ToolkitProvider>
  );
};

export default GenericSearchTable;
/*
 <div className="d-flex justify-content-end align-items-center">
              <PaginationProvider  pagination={pagination}>
                {({
      paginationProps,
    }) => <PaginationTotalStandalone {...paginationProps} />}
              </PaginationProvider>
            </div>
*/
