import React, { useState, useCallback, useRef, useEffect } from "react";
import styles from "./index.module.scss";
import classNames from "classnames";
import { Col } from "react-bootstrap";
import validate from "validate.js";
import IntlCurrencyInput from "react-intl-currency-input";

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};
function Input({
  number,
  name,
  classname,
  label,
  handleChangeError,
  cols,
  handleChange,
  handleBlur,
  maximum,
  type,
  value,
  error,
  autoFocus,
  constraint,
  constraintEquality,
  ...rest
}) {
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [errors, setErrors] = useState(error);
  const [valueInput, setValueInput] = useState(value);
  useEffect(() => {
    if (autoFocus === true) {
      inputRef.current.focus();
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    return () => {};
  }, []);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleOnChange = (event, value, maskedValue) => {
    handleChange(value);
  };

  return (
    <Col
      col={cols.col}
      sm={cols.sm || cols.col}
      md={cols.md || cols.col}
      lg={cols.lg || cols.col}
      lx={cols.lx || cols.col}
      className={"form-group mb-3"}
    >
      <label htmlFor={name}>{label}</label>
      <IntlCurrencyInput
        id={name}
        onChange={handleOnChange}
        className={classNames(
          "form-control",
          styles.input,
          {
            [styles[`input--isFocused`]]: isFocused == true,
          },
          {
            [styles[`input--isFilled`]]: isFilled == true,
          },
          {
            [styles[`input--isError`]]: !!errors == true,
          },
          {
            [styles[`input--isError`]]: !!error == true,
          }
        )}
        onFocus={handleInputFocus}
        value={value}
        currency="BRL"
        config={currencyConfig}
        {...rest}
      />
      {errors && <span>{errors}</span>}
    </Col>
  );
}

export default Input;
