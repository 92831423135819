import layoutSettings from "./LayoutDefault/LayoutSettings";


export const LayoutSettings = {
  activeLayout: "layout1", // layout1, layout2
  dir: "ltr", // ltr, rtl
  layoutSettings,
  customizer: {
    show: true,
    open: true
  },
  footer: {
    show: true
  }
};
