import React from "react";
import { Card, Row, Col } from "react-bootstrap";

const SimpleCard = ({ title, children, className, subtitle, buttonComponent }) => {

  return (

      <Row>
        <Col>
          <Card className={className}>
            <Card.Body>{children}</Card.Body>
          </Card>
        </Col>
      </Row>

  );
};

export default SimpleCard;
