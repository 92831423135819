import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const Dashboard1 = lazy(() => import("./dashboard/Dashboard"));

const dashboardRoutes = [
  {
    path: "/painel",
    component: Dashboard1,
    auth: authRoles.admin
  }
];

export default dashboardRoutes;
