import produce from 'immer';

const INITIAL_STATE = {
  enum: null,
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draftState => {
    switch (action.type) {
      case '@enum/SET': {
        draftState.enum = action.payload;
        break;
      }
      default:
    }
  });
}