import { lazy } from "react";
const Signin = lazy(() => import("./Signin/Signin"));
const ResetPassword = lazy(() => import("./ResetPassword"));
const ForgotPassword = lazy(() => import("./ForgotPassword/ForgotPassword"));
const Error404 = lazy(() => import("./Error"));

const sessionsRoutes = [
  {
    path: "/session/login",
    component: Signin
  },
  {
    path: "/session/recuperar",
    component: ForgotPassword
  },
  {
    path: "/session/resetar/:token",
    component: ResetPassword
  },
  {
    path: "/session/404",
    component: Error404
  }
];

export default sessionsRoutes;
