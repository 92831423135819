import gql from "graphql-tag";

export const subscriptionShowEstacao = (id_estacao) =>  `subscription MyQuery {
    estacao(where: {_and: {_and: {id_estacao: {_eq: ${id_estacao}}}}}) {
      cd_situacao
    cd_municipio
    cd_tipo_contrato
    cd_intensidade_radio
    concentrador_sta
    cd_atualizacao_intensidade_radio
    cnpj_empresa
    nome
    codigo
    data_periodo_atual
    data_ultimo_contrato
    data_ultimo_periodo_pago
    valor_por_unidade
    dia_fechamento
    dia_vencimento
    id_estacao
    latitude
    longitude
    maquinas_liberadas
    nome_rede
    data_hora_ultima_sincronizacao
    venda_automatica
    versao_estacao {
      cd_versao_disponivel
      cd_versao
      cd_tipo_dispositivo
      tipo_dispositivo
      versao_atribuida
      versao {
        numero
        data_liberacao
        validada_producao
      }
    }
    municipio {
      uf
      municipio 
    }
    nome_empresa
    observacoes
    situacao {
      codigo
      descricao
    }
    tipo_contrato {
      codigo
      descricao
    }
    intensidade_radio {
      codigo
      descricao
    }
    sinal_fim_banho_hub
    atualizacao_hora_inicio
    atualizacao_horas_duracao
    atualizacao_intensidade_radio {
      codigo
      descricao
    }
    }
  }`;
  export const subscriptionList = (mostra_desativadas) =>  `subscription getEstacao {
    estacao (where: {situacao: {codigo: { ${ (!mostra_desativadas) ? "_gt" : "_eq"} : 0}}}, order_by: {nome: asc}) {
      id_estacao
      nome
      nome_rede
      municipio {
        municipio
        uf
      }
      data_hora_ultima_comunicacao
      qtd_processos_ultimos_30_dias
      qtd_violacoes_ultimos_30_dias
      data_ultimo_periodo_pago
      estado_pagamento
      qtd_dispositivos_alocado
      estado_dos_dispositivos
      estado_pagamento
    }
  }`;


  export const EstacoesNaoVinculadasAoUsuario = (cd_usuario) =>  `subscription EstacoesNaoVinculadasAoUsuario($_cd_usuario: bigint = ${cd_usuario}) {
    estacao(where: {_and: {_not: {usuario_estacaos: {cd_usuario: {_eq: $_cd_usuario}}}}, _not: {usuario_estacaos: {}}}) {
      id_estacao
      codigo
      nome
    }
  }`;

  export const buscarEstacao = (nome) =>  `subscription BuscaEstacao{
    estacao(distinct_on: [nome_rede, nome], where: {_or: [{nome_rede: {_ilike: "%${nome?nome:'1q2w3e4r5t6y7u8i9o0p'}%"}}, {nome: {_ilike: "%${nome?nome:'1q2w3e4r5t6y7u8i9o0p'}%"}}]}) {
      nome_rede
      nome
      id_estacao
    }
  }`;

export const queryResumo = gql(`
  query OperacoesEstacao {
    estacoes_situacao: enum(where: {deleted_at: {_is_null: true}, entidade: {_eq: "SITUACAO_ESTACAO"}}) {
      entidade
      codigo
      descricao
      estacaos_aggregate {
        aggregate {
          count
          sum {
            maquinas_liberadas
          }
        }
      }
    }
    estacao_ativa_inadimplente {
      estacoes_ativa_inadimplente
      maquinas_liberadas_inadimplente
    }
  }  
`);

export const findSituations = () => `
  query situacoesEstacao {
    situacoes: enum(where: {entidade: {_eq: "SITUACAO_ESTACAO"}}, order_by: {codigo: asc}) {
      codigo
      descricao
    }
  }
`;
