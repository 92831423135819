import { lazy } from "react";


const Conta = lazy(() => import("./Conta"));

const userRoutes = [
  {
    path: "/minha-conta",
    component: Conta,

  },
];

export default userRoutes;
