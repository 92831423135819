export function setEstacaoPadrao(data) {
  return {
    type: '@estacao/SET',
    payload: data,
  };
}
export function setEstacaoClear() {
  return {
    type: '@estacao/CLEAR',
    payload: [],
  };
}
export function setEstacaoAdd(data) {
  return {
    type: '@estacao/ADD',
    payload: data,
  };
}
export function setEstacaoArray(data) {
  return {
    type: '@estacao/ADDARRAY',
    payload: data,
  };
}
export function setEstacaoRemove(data) {
  return {
    type: '@estacao/REMOVE',
    payload: data,
  };
}
