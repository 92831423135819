import produce from 'immer';

const INITIAL_STATE = {
  estacao: [],
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draftState => {
    switch (action.type) {
      case '@estacao/SET': {
        draftState.estacao =[];
        break;
      }
      case '@estacao/ADD': {
        draftState.estacao = [...draftState.estacao, action.payload ];
        break;
      }
      case '@estacao/REMOVE': {

        draftState.estacao = draftState.estacao.filter(item=>{
          return item.id_estacao != action.payload
        });
        break;
      }
      case '@estacao/CLEAR': {
        draftState.estacao = []
        break;
      }
      case '@estacao/ADDARRAY': {
        draftState.estacao = [...draftState.estacao, ...action.payload ]
        break;
      }
      default:
    }
  });
}