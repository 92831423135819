import React, { useState, useCallback, useRef, useEffect } from "react";
import validate from "validate.js";
import { Col } from "react-bootstrap";
import styles from "./index.module.scss";
import classNames from "classnames";
function Input({
  name,
  classname,
  label,
  handleChangeError,
  cols,
  handleChange,
  handleBlur,
  value,
  maximum,
  type,
  error,
  autoFocus,
  constraint,
  constraintEquality,
  ...rest
}) {
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [errors, setErrors] = useState(error);
  

  useEffect(() => {
    if (autoFocus === true) {
      inputRef.current.focus();
    }
    
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    return () => {};
  }, []);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);
  const handleInputBlur = useCallback(async () => {
    setIsFocused(false);
    setIsFilled(!!inputRef.current?.value);
    if (constraint) {
      const inputValue =
        type == "number"
          ? parseInt(inputRef.current.value)
          : inputRef.current.value;
      await validate
        .async({ [name]: inputValue }, { [name]: constraint })
        .then(null, (err) => {
          setErrors(err[name][0]);
          handleChangeError(true);
        });
    }
    // eslint-disable-next-line
  }, []);
  const handleChangeString = useCallback(
    (event) => {
      if (maximum) {
        event.target.value.length - 1 < maximum &&
          handleChange(event.target.value);
      } else {
        handleChange(event.target.value);
      }
      setErrors(false);
    },
    // eslint-disable-next-line
    []
  );
  const handleChangeNumber = useCallback(
    (event) => {
      if (Number.isInteger(parseInt(event.target.value))) {
        maximum
          ? event.target.value.length < maximum &&
            handleChange(parseInt(event.target.value))
          : handleChange(parseInt(event.target.value));
      } else {
        handleChange("");
      }
      setErrors(false);
    },
    [handleChange, maximum]
  );
  return (
    <Col
      col={cols.col}
      sm={cols.sm || cols.col}
      md={cols.md || cols.col}
      lg={cols.lg || cols.col}
      lx={cols.lx || cols.col}
      className={"form-group mb-3"}
    >
      <label htmlFor={name}>{label}</label>
      <input
        ref={inputRef}
        id={name}
        onChange={(event) => {
          switch (type) {
            case "number":
              return handleChangeNumber(event);
            default:
              return handleChangeString(event);
          }
        }}
        className={classNames(
          "form-control",
          styles.input,
          {
            [styles[`input--isFocused`]]: isFocused == true,
          },
          {
            [styles[`input--isFilled`]]: isFilled == true,
          },
          {
            [styles[`input--isError`]]: !!errors == true,
          },
          {
            [styles[`input--isError`]]: !!error == true,
          }
        )}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        value={value}
        type={type}
        
        {...rest}
        autocomplete="off"
      ></input>
      {errors && <span>{errors}</span>}
    </Col>
  );
}

export default Input;
