import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const Enum = lazy(() => import("./Enum/Listagem/Listagem"));
const TipoDispositivos = lazy(() => import("./TipoDispositivos/Listagem/Listagem"));

const MunicipioListagem = lazy(() => import("./Municipio/Listagem/Listagem"));
const geralRoutes = [
  {
    path: "/geral/enum/:enumUrl",
    component: Enum,
  },
  {
    path: "/geral/tipo-dispositivo",
    component: TipoDispositivos,
  },
  {
    path: "/geral/municipio",
    component: MunicipioListagem,
  },

];

export default geralRoutes;
