import React, { Suspense } from "react";
import Layout  from './LayoutDefault/Layout'
function GullLayout({ route}) {
  return <Suspense>
  <Layout routes={route.routes}></Layout>

</Suspense>;
}

export default GullLayout;
