import React, { useState, useCallback, useRef,  } from "react";
import styles from "./index.module.scss";
import classNames from "classnames";
import DatePicker, {registerLocale} from "react-datepicker";
import { Col } from "react-bootstrap";
import br from 'date-fns/locale/pt-BR';

registerLocale('br', br)
function DateT({ name, label, error, cols, handleChange, value, style,   ...rest }) {
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
 
  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);
  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    setIsFilled(!!inputRef.current?.value);
  }, []);
  const onChangeInputHandle = useCallback((date) => {
    handleChange(date)
     }, []);
  return (
    <Col
      col={cols.col}
      sm={cols.sm || cols.col}
      md={cols.md || cols.col}
      lg={cols.lg || cols.col}
      lx={cols.lx || cols.col}
      className={"form-group mb-3"}
    >
      <label htmlFor={name}>{label}</label>
      <br/>
      <DatePicker
        ref={inputRef}
        id={name}
        selected={value}
        onChange={date => onChangeInputHandle(date)}
        className={classNames('form-control',
          styles.input,
          {
            [styles[`input--isFocused`]]: isFocused == true,
          },
          {
            [styles[`input--isFilled`]]: isFilled == true,
          },
          {
            [styles[`input--isError`]]: error == true,
          }
        )}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        locale="br"
        style={{...style}}
        autocomplete="off"
        {...rest}
      ></DatePicker>
    </Col>
  );
}

export default DateT;
