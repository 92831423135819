import React from 'react'
import { BiWifi0, BiWifi1, BiWifi2, BiWifiOff, BiWifi } from "react-icons/bi";

export default function Index(sinal) {
    const Sinal = (sinal) => {
        switch (sinal) {
          case 0:
            return <BiWifiOff size="20"></BiWifiOff>;
          case 1:
            return <BiWifi0 size="20"></BiWifi0>;
          case 2:
            return (
              <BiWifi1
                size="20"
               
              ></BiWifi1>
            );
          case 3:
            return (
              <BiWifi2
                size="20"
             
              ></BiWifi2>
            );
          case 4:
            return (
              <BiWifi
                size="20"
             
              ></BiWifi>
            );
          default:
            return (
              <BiWifi
                size="20"
              
              ></BiWifi>
            );
        }
      };
    return (
        <div>
            {Sinal(sinal)}
        </div>
    )
}
