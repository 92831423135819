import { all, call, put, takeLatest } from "redux-saga/effects";
//import { toast } from "react-toastify";
import { NotificationManager } from "react-notifications";

import history from "../../../../@history";
import api from "../../../services/connection-broker/api";
import storage from "../../../services/storage";


import { signInSuccess, signFailure } from "./actions";
import { updatePermissoes } from "../permissoes/actions";

export function* signIn({ payload }) {
  try {
    const { email, password } = payload;
    const response = yield call(api.post, "auth/login", {
      email,
      password
    });

    const { access_token, } = response.data;

    if (access_token) {
      api.defaults.headers.Authorization = `Bearer ${access_token}`;
      const responseMe = yield call(api.get, "auth/me");
      const responsePermissoes = yield call(api.get, "auth/me/permissoes/resumido");
      const user = responseMe.data;
      const permissoes = responsePermissoes.data.permissoes;
      yield put(signInSuccess(access_token, user));
      yield put(updatePermissoes(permissoes));
      window.location.href = "/estacao";
      //history.push("");
      storage.set('user', user);
      storage.setString('access', access_token);
    }
    // add token to requests by default
  } catch (error) {

    yield put(signFailure());
    NotificationManager.warning("E-mail ou senha incorretos! Por favor, tente novamente");
  }
}

export function* signUp() {
  
}

export function setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export function signOut() {
  history.push("/");
  localStorage.clear();
}

export default all([
  takeLatest("persist/REHYDRATE", setToken),
  takeLatest("@auth/SIGN_IN_REQUEST", signIn),
  takeLatest("@auth/SIGN_UP_REQUEST", signUp),
  takeLatest("@auth/SIGN_OUT", signOut)
]);
