import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const Listagem = lazy(() => import("./Listagem/Listagem"));
const Novo = lazy(() => import("./Novo/Novo"));
const Operacao = lazy(() => import("./operacao/Operacao"));
const Estacao = lazy(() => import("./Estacao/Estacao"));
const userRoutes = [
  {
    path: "/estacao/cadastro",
    component: Novo,
    exact:true,
    auth: authRoles.admin
  },
  {
    path: "/estacao/estacao/:id",
    component: Estacao,
    exact:true,
    auth: authRoles.admin
  },
  {
    path: "/estacao",
    component: Listagem,
    exact:true,
    auth: authRoles.admin
  },

  {
    path: "/estacao/operacao",
    component: Operacao,
    exact:true,
    auth: authRoles.admin
  },


];

export default userRoutes;
